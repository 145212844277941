import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/bs/layout"
//import Image from "../components/image"
import SEO from "../../components/bs/seo"

import MainImg from "../../images/main_bg.jpg"

import shape1 from "../../images/dec/1.png"
import shape2 from "../../images/dec/2.png"
import shape3 from "../../images/dec/3.png"
import shape4 from "../../images/dec/4.png"
import shape5 from "../../images/dec/5.png"
import shape6 from "../../images/dec/6.png"
import shape7 from "../../images/dec/7.png"
import shape8 from "../../images/dec/8.png"

import product1 from "../../images/moonamar-hladno-cijedeno-ulje-corokota-crnog-kima.png"
import product2 from "../../images/moonamar-hladno-cijedeno-bundevino-bucino-ulje.png"
import product3 from "../../images/moonamar-konopljino-ulje-iz-organskog-bio-sjemena.png"
import product4 from "../../images/moonamar-hladno-cijedeno-djevicansko-laneno-ulje.png"

const KonopljinoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA -  KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
      description="IMMMUNO NATURA hladno cijeđeno konopljino ulje - prirodno ulje koje se dobija iz organskog (bio) sjemena ne izlaže dodatnim preradama."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product3}
                alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">Konopljino ulje iz BIO sjemena</div>
                  <div className="subtitle">
                    Uvijek u centru zbivanja – Vaš saveznik u borbi protiv
                    metaboličkih poremećaja
                  </div>
                  <p className="content">
                    Konopljin fantastičan biohemijski sastav je potencijal koji
                    je izdiže iznad svih predrasuda i ponovo uvodi u upotrebu
                    kao izvanrednu potporu organizmu protiv metaboličkih
                    poremećaja.
                  </p>
                  <p className="content">
                    Pogodno je kako za oralnu upotrebu tako i za utrljavanje u
                    kožu.
                  </p>
                  <div className="price">22,90 KM</div>
                </div>
                <div className="_buttonShop">
                  <a href="https://zdravo.ba/proizvod/zdravlje/moonamar/konopljino-ulje-250-ml">
                    KUPI PROIZVOD
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Opis proizvoda</Tab>
                <Tab>Sastojci i upotreba</Tab>
                <Tab>Prirodni talenat</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    IMMMUNO NATURA konopljino ulje se dobija procesom hladnog
                    cijeđenja iz <strong>organskih (BIO)</strong> sjemenki
                    konoplje, ne filtrira se i ne izlaže dodatnom procesiranju,
                    čime zadržava sve korisne supstance sadržane u sjemenu.
                  </p>
                  <p>
                    <strong>PROCES CIJEĐENJA</strong>: hladno cijeđeno.
                    <br />
                    <strong>KVALITET</strong>: nerafinisano, 100% prirodno.
                    <br />
                    <strong>VRSTA SJEMENA</strong>: organsko (BIO) sjeme
                    Cannabis sativa L.
                    <br />
                    <strong>NAČIN PROIZVODNJE</strong>: prirodan, bez dodatnog
                    procesiranja.
                    <br />
                    <strong>DODACI PREPARATU</strong>: bez ikakvih dodatnih
                    sastojaka, 100% sjemenke industrijske konoplje.
                    <br />
                    <strong>STABILNOST</strong>: zbog visokog sadržaja
                    nezasićenih masnih kiselina nije stabilno na
                    povi&scaron;enim temperaturama, te se ne preporučuje za
                    kuhanje i prženje.
                    <br />
                    <strong>SKLADI&Scaron;TENJE</strong>: nakon otvaranja čuvati
                    u frižideru i upotrijebiti u roku od dva mjeseca.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="168">
                          <p>
                            <strong>Nutritivna vrijednost</strong>
                          </p>
                        </td>
                        <td width="132">
                          <p>
                            <strong>na 100 g</strong>
                          </p>
                        </td>
                        <td width="151">
                          <p>
                            <strong>Na 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Kalorijska vrijednost</p>
                        </td>
                        <td width="132">
                          <p>3685 kJ/896 kcal</p>
                        </td>
                        <td width="151">
                          <p>184,25 kJ/44,8 kcal</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>
                            Masnoće
                            <br />
                            <em>
                              -nezasićene masne kiseline (od toga: omega-6
                              omega-3)
                            </em>
                          </p>
                          <p>&nbsp;</p>
                        </td>
                        <td width="132">
                          <p>99,9 g</p>
                          <p>75,79 g</p>
                          <p>&nbsp;</p>
                          <p>56,60</p>
                          <p>19,19</p>
                        </td>
                        <td width="151">
                          <p>4,99 g</p>
                          <p>3,78 g</p>
                          <p>&nbsp;</p>
                          <p>2,83 g</p>
                          <p>0,95 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Ugljikohidrati</p>
                        </td>
                        <td width="132">
                          <p>0 g</p>
                        </td>
                        <td width="151">
                          <p>0 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Bjelančevine</p>
                        </td>
                        <td width="132">
                          <p>0 g</p>
                        </td>
                        <td width="151">
                          <p>0 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>SASTAV</h3>
                  <p>
                    Sjemenke konoplje sadrže 20-25% proteina, 20-30%
                    ugljikohidrata, te 10-15% netopivih vlakana, &scaron;to ih
                    čini izrazito nutritivno vrijednom hranom. Sadrže
                    antioksidanse, proteine, karotene, fitosterole, fosfolipide
                    te značajan udio minerala, uključujući kalcij, magnezij,
                    sumpor, kalij, cink i fosfor. Izvor su ukupnih proteina koji
                    sadrže svih 20 poznatih aminokiselina, uključujući i 9
                    esencijalnih aminokiselina. Također sadrže vitamin A
                    (&beta;-karoten), vitamine B-skupine (osim B12), D i E u
                    probavljivom obliku.
                  </p>
                  <p>
                    Ulje konoplje sadrži pretežno esencijalne masne kiseline i
                    to omega-6, linolnu masnu kiselinu oko 50%, omega-3,
                    &alpha;-linolensku masnu kiselinu oko 20%, i
                    &gamma;-linolensku kiselinu čiji sadržaj se kreće od 1-4%.
                    Ima uravnotežen omjer omega-3 i omega-6 masnih kiselina koji
                    je gotovo idealan i iznosi 3:1.{" "}
                    <strong>
                      Utvrđeno je da se takav omjer masnih kiselina nalazi u
                      mediteranskoj i japanskoj
                    </strong>{" "}
                    <strong>
                      prehrani, za koje se smatra da imaju jedan od
                      najpovoljnijih efekata na krvožilni sistem ljudi.{" "}
                    </strong>
                    Svojim sastavom konopljino ulje osigurava sve potrebe za
                    esencijalnim masnim kiselinama, uključujući i
                    &gamma;-linolensku masnu kiselinu, dok 10%-tni sadržaj
                    zasićenih masnih kiselina osigurava energiju.
                  </p>
                  <p>
                    Osim povoljnog sastava masnih kiselina, konopljino ulje je
                    odličan izvor bioaktivnih komponenata poput tokoferola,
                    tokotrienola i plastokromanola-8 (PC-8). Tokoferoli su
                    prirodni antioksidansi koji sprečavaju oksidaciju
                    nezasićenih masnih kiselina te smanjuju rizik od
                    kardiovaskularnih bolesti i tumora.
                  </p>
                  <p>
                    Konopljino ulje ima dobre senzorne karakteristike i dobru
                    oksidativnu stabilnost, kojoj doprinosi povoljan sastav
                    bioaktivnih komponenata u odnosu na sastav masnih kiselina.
                    <br /> Hladno cijeđeno konopljino ulje je tamnozelene do
                    svijetlozelene boje i ugodnog ora&scaron;astog okusa. Zelena
                    boja ulja potječe od visokog udjela hlorofila, koji je
                    prirodno prisutan u sjemenkama konoplje. Osim hlorofila u
                    značajnom udjelu prisutni su i karotenoidi, a oni zajedno
                    određuju boju ulja. Osim toga, posjeduju i antioksidativna
                    svojstva, te time doprinose nutritivnoj vrijednosti ulja.
                  </p>
                  <h3>UPOTREBA</h3>
                  <p>
                    <strong>
                      Postoje nekoliko razloga zbog čega se koristi konopljino
                      ulje:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      zbog visokog sadržaja esencijalnih masnih kiselina ima
                      veliku prednost u odnosu na druga biljna ulja; obezbjeđuje
                      organizmu niz neophodnih materija i pomaže u prevenciji
                      različitih bolesti;
                    </li>
                    <li>
                      pažljivo proizvedeno i skladi&scaron;teno ulje ima dobar
                      senzorni kvalitet, pa se upotrebljava kao dodatak u
                      pripremi različitih jela;
                    </li>
                    <li>
                      kao sastojak kozmetičkih preparata &scaron;titi kožu i
                      usporava proces starenja;
                    </li>
                  </ul>
                  <p>KAKO KORISTITI KONOPLJINO ULJE?</p>
                  <ul>
                    <li>
                      <strong>PMS:</strong> 1-3 ka&scaron;ičice dnevno (zavisno
                      od tjelesne težine) u trajanju od tri mjeseca.
                    </li>
                    <li>
                      <strong>
                        Neurodermatitis i kardiovaskularne bolesti
                      </strong>
                      : 4-8 ka&scaron;ičica dnevno (zavisno od tjelesne težine)
                      u trajanju od tri mjeseca.
                    </li>
                    <li>
                      <strong>Reumatoidni artritis</strong>: 8-10
                      ka&scaron;ičica dnevno (zavisno od tjelesne težine) u
                      trajanju od tri mjeseca.
                    </li>
                  </ul>
                  <p>
                    Osim u ishrani, ulje konoplje također se koristi i u
                    kozmetici jer djeluje antimikrobno, antiupalno, sprečava
                    starenje kože, uravnotežuje pH i vlažnost kože, te djeluje
                    <br /> antioksidativno.
                  </p>
                  <p>
                    <br />
                    <br />
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    Povoljni uticaji omega-3 masnih kiselina vidljivi su u
                    prevenciji kardiovaskularnih oboljenja (te time moždanog i
                    srčanog udara), tumora, reumatoidnog artritisa te
                    neurodermatitisa i psorijaze. Upravo zbog tih svih utvrđenih
                    činjenica, vrlo je važno voditi računa u omjeru omega-6 i
                    omega-3 masne kiseline u prehrani. Taj bi omjer u prehrani
                    trebao biti 3:1 ili 4:1, jer povoljno utiče na prevenciju i
                    tretman kod krvožilnih bolesti, osteoporoze, dijabetesa,
                    artritisa, tumora, mentalnih bolesti te različitih upalnih i
                    imunolo&scaron;kih poremećaja.
                  </p>
                  <p>
                    <strong>
                      Prirodni talenat za blagotvorno djelovanje na
                      neurodermatitis
                    </strong>
                  </p>
                  <p>
                    Pacijenti koji boluju od ove bolesti, pate od
                    nepodno&scaron;ljivog svraba, naročito noću. Koža je veoma
                    suha, aktivnost lojnih i znojnih žlijezda je veoma niska.
                    Neurodermatitis se karakteri&scaron;e velikim gubitkom vode
                    kroz kožu. Nedostatak esencijalnih masnih kiselina je jedan
                    od najče&scaron;ćih uzroka ove pojave. Esencijalne masne
                    kiseline imaju jak uticaj na funkciju kože, posebno vezane
                    za regulisanje gubitka vode. Konopljino ulje može mnogo
                    doprinijeti u tretiranju ove bolesti. Dokazano je da
                    uno&scaron;enjem dnevne doze od 18 grama, tj. četiri
                    ka&scaron;ičice konopljinog ulja dnevno, tokom 12 sedmica
                    dolazi do pobolj&scaron;anja.
                  </p>
                  <p>
                    <strong>
                      Prirodni talenat za smanjenje rizika od kardiovaskularnih
                      bolesti
                    </strong>
                  </p>
                  <p>
                    Većina kardiovaskularnih bolesti koje ugrožavaju zdravlje
                    ljudi &scaron;irom svijeta prouzrokovana je nagomilavanjem
                    komponenti na unutra&scaron;njim zidovima krvnih sudova.
                    Ovaj proces može dovesti do ateroskleroze ili srčanog udara.
                    Pored faktora, kao &scaron;to su pu&scaron;enje i stres,
                    unos zasićenih masnih kiselina prisutnih u mastima
                    životinjskog porijekla je glavni uzrok visokog nivoa
                    lo&scaron;eg holesterola u krvi. S druge strane, dijetetskim
                    tretmanom pacijenata sa dnevnim dozama linolne i
                    &gamma;-linolenske kiseline (četiri ka&scaron;ičice
                    konopljinog ulja) brzo se smanjuje nivo lo&scaron;eg i
                    ukupnog holesterola u krvi. Dakle, zamjenom ulja i masti sa
                    visokim udjelom zasićenih masnih kiselina konopljinim uljem,
                    smanjuje se rizik od ateroskleroze i drugih
                    kardiovaskularnih bolesti.
                  </p>
                  <p>
                    <strong>
                      Prirodni talenat za smanjenje simptoma reumatoidnog
                      artritisa
                    </strong>
                  </p>
                  <p>
                    Neke masne kiseline, uključujući &gamma;-linolensku, su
                    značajan anti-inflamatorni faktor, kao i faktor stimulacije
                    imunog sistema. Dnevna doza od 1,2-1,4 g &gamma;-linolenske
                    kiseline, &scaron;to odgovara osam ka&scaron;ičica
                    konopljinog ulja dnevno tokom dvanaest sedmica, značajno
                    elimini&scaron;e simptome reumatoidnog artritisa.
                  </p>
                  <p>
                    <strong>PMS</strong>
                  </p>
                  <p>
                    PMS ili predmenstrualni sindrom podrazumijeva muskularne
                    tenzije različitog intenziteta, napetost i razdražljivost,
                    kao i agresivnost i depresiju. Istraživanja su pokazala da
                    žene sa PMS pate od poremećaja u metabolizmu masnih
                    kiselina, pri čemu se smanjuje sposobnost pretvaranja
                    linolne u &gamma;-linolensku kiselinu i dalje u
                    prostaglandin. Dnevna doza od 1,37 g linolne i 156 miligrama
                    &gamma;-linolenske kiseline, &scaron;to odgovara jednoj
                    ka&scaron;ičici konopljinog ulja dnevno, tokom dvanaest
                    sedmica značajno otklanja simptome PMS.
                  </p>
                  <p>
                    <strong>Ostala oboljenja</strong>
                  </p>
                  <p>
                    Novija istraživanja sugeri&scaron;u primjenu esencijalnih
                    masnih kiselina i &gamma;-linolenske kiseline kao
                    preventivnu mjeru u tretiranju multiple skleroze,
                    &scaron;izofrenične psihoze i kancera. Multipla skleroza se
                    če&scaron;će pojavljuje u područjima gdje se u ishrani unose
                    prevelike količine zasićenih masnih kiselina. Dodatak u
                    ishrani nezasićenih masnih kiselina može imati pozitivan
                    efekat na tok ove bolesti. Kod pacijenata sa
                    &scaron;izofreničnom psihozom otkriveni su poremećaji u
                    metabolizmu masnih kiselina, &scaron;to može biti tretirano
                    primjenom esencijalnih masnih kiselina. Primjena linolne i
                    &gamma;-linolenske kiseline može pomoći u terapiji kancera.
                    Ćelije i tkivo kancera imaju manji sadržaj
                    &gamma;-linolenske kiseline i odgovarajućih produkata
                    metabolizma od zdravih ćelija. Linolna kiselina podstiče
                    odumiranje ćelija kancera. Očigledno je da povećan unos
                    esencijalnih masnih kiselina i &gamma;-linolenske kiseline
                    na račun zasićenih masnih kiselina je efikasan način
                    prevencije ovih i drugih bolesti. Kori&scaron;tenje
                    konopljinog ulja u ishrani obezbjeđuje onoliko esencijalnih
                    masnih kiselina koliko je potrebno, i to u odgovarajućem
                    međusobnom odnosu.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>

    <section id="_listProduct" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <nav className="level">
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/corokotovo-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product1}
                        alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                        title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/bundevino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product2}
                        alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                        title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/konopljino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product3}
                        alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                        title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/laneno-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product4}
                        alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                        title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default KonopljinoUlje
